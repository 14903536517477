p {
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
}

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
  margin-top: 0;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  color: #fff;
}

ul {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

img {
  max-width: 100%;
}

a {
  transition-duration: .3s;
}

a:hover {
  color: #ff3030;
  text-decoration: none;
}

.section-title {
  color: #fff;
  margin-bottom: 75px;
  font-weight: 300;
}

.bg-header {
  z-index: 9;
  color: #fff;
  margin-bottom: 27px;
  padding: 40px 0 74px;
  position: relative;
}

.bg-header .bg-head-title {
  color: #fff;
  margin: 0;
  font-weight: 400;
}

#header {
  z-index: 99;
  background: #000;
  width: 100%;
  margin-top: 6px;
  position: absolute;
}

.main-navigation > li {
  position: relative;
}

.main-navigation > li > a {
  color: #fff;
  text-transform: uppercase;
  padding: 35px 0;
  font-size: 18px;
  font-weight: 500;
  transition-duration: 0s;
  display: inline-block;
}

.main-navigation > li > a:hover {
  color: #ff3030;
  border-top: 6px solid #ff3030;
  margin-top: -6px;
}

.main-navigation > li ul {
  z-index: 99;
  width: 220px;
  margin: 0;
  padding: 0;
  display: none;
  position: absolute;
  top: 94px;
  left: 0;
}

.main-navigation > li ul li {
  background: #fff;
  width: 100%;
  margin: 0 0 1px;
  display: inline-block;
  position: relative;
}

.main-navigation > li ul li:first-child {
  margin-top: 1px;
}

.main-navigation > li ul li:hover {
  background: #ffec45;
}

.main-navigation > li ul li ul {
  display: none;
  position: absolute;
  top: 0;
  left: 220px;
}

.main-navigation > li ul li ul li ul {
  left: auto;
  right: 220px;
}

.main-navigation > li ul a {
  color: #727272;
  text-transform: uppercase;
  width: 100%;
  padding: 10px;
  font-weight: 700;
  display: inline-block;
}

.logo {
  float: left;
  height: 95px;
  display: table;
}

.logo a {
  vertical-align: middle;
  display: table-cell;
}

.main-nav {
  float: right;
}

.main-nav li {
  float: left;
  margin-left: 49px;
}

.main-slider {
  overflow: hidden;
}

.main-slider .slider-contents-wrap {
  z-index: 9;
  background: url("slider-red-shape.3f109850.png") 100% 100% no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.main-slider .slider-contents {
  z-index: 9;
  text-align: center;
  height: 220px;
  position: absolute;
  bottom: 170px;
  left: 0;
  right: 0;
}

.main-slider h1 {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: -6px;
  margin: 0;
  font-size: 100px;
  font-style: italic;
  font-weight: 700;
}

.main-slider h1 strong {
  color: #ff3030;
}

.slider-training-wrap {
  background: #0006;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.slider-training-wrap .wrap {
  width: 100%;
  height: 170px;
  display: table;
}

.slider-training-wrap .wrap:hover {
  cursor: pointer;
}

.slider-training-wrap .wrap:hover h3, .slider-training-wrap .wrap:hover i {
  color: #ff3030;
}

.slider-training-wrap h3, .slider-training-wrap i {
  vertical-align: middle;
  color: #fff;
  text-transform: uppercase;
  font-weight: 300;
  display: table-cell;
}

.slider-training-wrap .fa {
  color: #888;
  font-size: 40px;
}

.slider-training-post {
  color: #fff;
  text-transform: uppercase;
  background: #000c;
  width: 700px;
  height: 170px;
  display: table;
}

.slider-training-post .post-contents {
  color: inherit;
  width: 265px;
}

.slider-training-post p {
  font-weight: 300;
}

.slider-training-post h5 {
  color: inherit;
  margin: 0 0 15px;
  font-size: 18px;
  font-weight: 500;
}

.slider-training-post h5 a {
  color: inherit;
}

.slider-training-post p a {
  color: #ff3030;
  text-decoration: underline;
}

.slider-training-post .fa {
  width: 50px;
}

.slider-training-post .post-contents {
  vertical-align: middle;
  display: table-cell;
}

.home-classes {
  background-color: #0000;
  background-image: linear-gradient(#000000b3, #000000b3), url("home-clesses-bg.44b0dd87.webp");
  background-position: 0 0, 50% 0;
  background-repeat: repeat, repeat;
  background-size: cover;
  background-attachment: scroll, fixed;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.classes .section-header {
  margin-bottom: 40px;
}

.classes .class {
  position: relative;
  overflow: hidden;
}

.classes .class .title {
  text-align: right;
  z-index: 9;
  color: #303030;
  box-sizing: border-box;
  background: #edededd9;
  width: 100%;
  margin: 0;
  padding: 33px;
  font-weight: 700;
  transition-duration: .4s;
  position: absolute;
  bottom: 0;
  left: 0;
}

.classes .class .title a:hover {
  color: #ff3030 !important;
}

.classes .class:hover .title {
  background: #ff6464bf;
}

.classes .class:hover .title a {
  color: #fff;
}

.classes .class:hover img {
  transform: scale(1.1);
}

.classes .class img {
  transition-duration: .4s;
}

.classes .classes-wrap {
  padding: 75px 0;
}

.classes .classes-carousel-wrap {
  background: #212121e6;
  margin-bottom: 70px;
  position: relative;
}

.team-container {
  padding: 90px 0;
}

.team-container .section-header {
  margin-bottom: 70px;
}

.team-container .class {
  position: relative;
}

.team-container .title {
  z-index: 9;
  box-sizing: border-box;
  color: #fff;
  text-align: center;
  background: #000000b3;
  width: 100%;
  margin: 0;
  padding: 20px 10px;
  font-size: 18px;
  font-weight: 700;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.team-container p {
  text-align: center;
  color: #727272;
  font-size: 16px;
}

.home-news-articles {
  background: url("home-news-bg.b9fe3f1a.webp") 50% 0 / cover fixed;
}

.home-news-articles .section-title {
  margin-bottom: 45px;
}

.home-news-articles .home-news-bg {
  background: #303030cc;
  padding: 70px 0;
}

.home-news-articles .post {
  background: #000c;
  margin-bottom: 40px;
}

.home-news-articles .contents {
  margin: 35px 0 40px;
  display: table;
}

.home-news-articles h4, .home-news-articles p {
  margin-bottom: 15px;
}

.home-news-articles p {
  font-size: 16px;
  line-height: 28px;
}

.home-news-articles .entry-contents {
  margin-bottom: 40px;
}

.home-news-articles .meta {
  text-transform: uppercase;
}

.home-news-articles-2 {
  background: none;
  padding: 103px 0 79px;
}

.home-news-articles-2 .section-header {
  margin-bottom: 64px;
}

.home-news-articles-2 .home-news-bg {
  margin-bottom: 65px;
}

.home-news-articles-2 .post {
  margin: 0 -15px;
  position: relative;
}

.home-news-articles-2 .post:hover .contents {
  display: block;
  left: 0;
}

.home-news-articles-2 .contents {
  box-sizing: border-box;
  z-index: 9;
  color: #fff;
  background: #ff3030cc;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 40px;
  transition-duration: .5s;
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
}

.home-news-articles-2 .contents h4 a {
  color: #fff;
}

.home-news-articles-2 .home-news-bg {
  background: url("home-news-bg-2.7062f728.webp") 50% 0 / cover fixed;
  padding: 0;
}

.home-news-articles-2 .home-news-bg:before {
  content: " ";
  background: #303030cc;
  width: 100%;
  height: 100%;
  position: absolute;
}

.classes.team-container {
  background-color: #0000;
  background-image: linear-gradient(#000000b3, #000000b3), url("team-bg.44b0dd87.webp");
  background-position: 0 0, 50% 0;
  background-repeat: repeat, repeat;
  background-size: cover;
  background-attachment: scroll, fixed;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  padding: 0;
}

.classes.team-container .section-header {
  margin-bottom: 42px;
}

.team-wrap header {
  margin-bottom: 50px;
}

.team-wrap header h2 {
  margin-bottom: 40px;
}

.team-wrap header p {
  color: #fff;
}

.team-wrap .team-carousel .class {
  text-align: center;
  position: relative;
}

.team-wrap .team-carousel .class:hover h4 {
  background: #ff6464bf;
}

.team-wrap .team-carousel h4 {
  text-align: center;
  color: #fff;
  background: #303030d1;
  width: 100%;
  margin: 0;
  padding: 15px;
  font-size: 18px;
  transition-duration: .3s;
  position: absolute;
  bottom: 0;
  left: 0;
}

.team-wrap .team-carousel-wrap {
  background: #ff646480;
  margin-bottom: 86px;
  position: relative;
}

#footer {
  color: #fff;
  background-color: #0000;
  background-image: linear-gradient(#000000b3, #000000b3), url("footer-bg.f66b0ae4.webp");
  background-position: 0 0, 50% 0;
  background-repeat: repeat, repeat;
  background-size: cover;
  background-attachment: scroll, fixed;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  position: relative;
}

#footer .footer-wrapper {
  padding: 100px 0;
}

#footer .footer-logo {
  margin-bottom: 30px;
}

#footer p {
  border-bottom: 1px solid #656565;
  margin-bottom: 40px;
  padding-bottom: 34px;
  font-size: 18px;
}

#footer .footer-meta {
  font-size: 16px;
}

#footer .footer-meta .same {
  color: inherit;
  display: inline-block;
}

#footer .footer-meta a {
  color: inherit;
}

#footer .footer-meta a:hover {
  color: #ff3030;
}

#footer .footer-bottom {
  background: #fff;
  padding: 20px 0;
}

#footer .footer-nav li {
  float: left;
  margin: 5px 38px 0 0;
}

#footer .footer-nav li:first-child {
  margin-left: 0;
}

#footer .footer-nav a {
  color: #9a9a9a;
  text-transform: uppercase;
}

#footer .footer-nav a:hover {
  color: #ff3030;
}

#footer .copyright {
  text-transform: uppercase;
  color: #9a9a9a;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

table {
  border-collapse: collapse;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

td, th {
  text-align: center;
  padding: 10px;
}

body {
  color: #fff;
  background-color: #000;
  font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
}
/*# sourceMappingURL=index.79c5211c.css.map */
